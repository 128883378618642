import { useRef, useState, useEffect } from 'react';
import '../css/home.css';
import { Layout, Typography, Menu, Divider, Popover, QRCode } from 'antd';
const { Link, Title, Text } = Typography;

const { Header, Content, Footer } = Layout;

const HEADER_HEIGHT = 88;
const CURRENT_YEAR = new Date().getFullYear();
const RECORD_CODE = 51019002007136;

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 假设 768px 是移动设备的断点
    };

    window.addEventListener('resize', handleResize);

    handleResize(); // 初始化时也调用一次

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const homeHeight = window.innerHeight - HEADER_HEIGHT;
  const contactRef = useRef<HTMLDivElement>(null);
  return (
    <div className="home">
      <Layout>
        <Header
          style={{
            height: `${HEADER_HEIGHT}px`,
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
            >
              <img
                src="/icon.png"
                alt="有速度，优速度"
                width={32}
                height={32}
              />
              <Link href="https://yousudu.com" target="_blank">
                <p style={{ color: '#0ff', marginLeft: 8, fontSize: 16 }}>
                  有速度，优速度
                </p>
              </Link>
            </div>

            {!isMobile && (
              <Menu
                style={{
                  height: `${HEADER_HEIGHT}px`,
                  lineHeight: `${HEADER_HEIGHT}px`,
                  backgroundColor: 'transparent',
                  fontSize: 18,
                }}
                mode="horizontal"
                defaultSelectedKeys={['1']}
                items={[
                  {
                    key: '1',
                    label: '首页',
                  },
                  {
                    key: '2',
                    label: '联系我们',
                  },
                ]}
                onClick={(item) => {
                  if (item.key === '1') {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth', // 平滑滚动
                    });
                  } else if (item.key === '2' && contactRef.current) {
                    const rect = contactRef.current.getBoundingClientRect();
                    const y = rect.y;
                    window.scrollTo({
                      top: y - HEADER_HEIGHT,
                      behavior: 'smooth', // 平滑滚动
                    });
                  }
                }}
              />
            )}
          </div>
        </Header>
        <Content>
          <div
            style={{
              height: `${homeHeight}px`,
              minHeight: '772px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Title
                style={{
                  marginTop: `${HEADER_HEIGHT}px`,
                  marginBottom: '6px',
                }}
              >
                一站式软件技术服务
              </Title>
              <Divider
                style={{
                  marginBottom: '6px',
                }}
              >
                <div
                  style={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%',
                    backgroundColor: '#00FFFF',
                  }}
                ></div>
              </Divider>
              <Text style={{ color: '#64686d' }}>
                One-stop software technology services
              </Text>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  marginTop: '100px',
                }}
              >
                {!isMobile && (
                  <div
                    style={{
                      width: '50%',
                      display: 'flex',
                      justifyContent: 'right',
                    }}
                  >
                    <img src="/main.png" alt="一站式软件技术服务" />
                  </div>
                )}
                <div
                  style={{
                    width: `${isMobile ? '100%' : '50%'}`,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ul
                    style={{
                      height: '80%',
                      display: 'flex',
                      listStyleType: 'none',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                    }}
                  >
                    <li>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src="/web.png"
                          width={32}
                          height={32}
                          alt="网站开发"
                        />
                        <div style={{ marginLeft: '16px' }}>
                          <Title
                            style={{
                              margin: '0px',
                            }}
                            level={5}
                          >
                            网站定制开发
                          </Title>
                          <Text style={{ color: '#64686d' }}>
                            网站开发、建站、维护、优化
                          </Text>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src="/phone.png"
                          width={32}
                          height={32}
                          alt="移动应用开发"
                        />
                        <div style={{ marginLeft: '16px' }}>
                          <Title
                            style={{
                              margin: '0px',
                            }}
                            level={5}
                          >
                            移动应用开发
                          </Title>
                          <Text style={{ color: '#64686d' }}>
                            Android、iOS、Harmony（鸿蒙）原生应用、React
                            Native、Flutter跨端应用前后端开发
                          </Text>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src="/mirco.png"
                          width={32}
                          height={32}
                          alt="小程序开发"
                        />
                        <div style={{ marginLeft: '16px' }}>
                          <Title
                            style={{
                              margin: '0px',
                            }}
                            level={5}
                          >
                            小程序、公众号建设推广
                          </Title>
                          <Text style={{ color: '#64686d' }}>
                            小程序开发，公众号建设、推广
                          </Text>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Title
                  level={2}
                  style={{
                    marginTop: '102px',
                  }}
                >
                  有速度，优速度
                </Title>
                <Text style={{ color: '#64686d' }}>
                  High efficiency and high quality
                </Text>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            ref={contactRef}
          >
            <Title
              style={{
                marginTop: `${HEADER_HEIGHT}px`,
                marginBottom: '6px',
              }}
            >
              联系我们
            </Title>
            <Divider
              style={{
                marginBottom: '6px',
              }}
            >
              <div
                style={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '50%',
                  backgroundColor: '#00FFFF',
                }}
              ></div>
            </Divider>

            <ul
              style={{
                height: '120px',
                padding: '0px',
                listStyleType: 'none',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <li>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img src="/email.png" width={24} height={24} alt="邮箱" />

                  <Link href="mailto:hi@yousudu.com" target="_blank">
                    <Title level={5} style={{ margin: '0px 6px' }}>
                      邮箱：hi@yousudu.com
                    </Title>
                  </Link>
                </div>
              </li>
              <li>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src="/wechat.png"
                    width={24}
                    height={24}
                    alt="企业微信"
                  />
                  {/* {isMobile ? (
                    <Link
                      href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004536"
                      target="_blank"
                    >
                      <Title level={5} style={{ margin: '0px 6px' }}>
                        企微：有速度科技
                      </Title>
                    </Link>
                  ) : ( */}
                  <Popover
                    content={
                      <QRCode
                        value={
                          'https://work.weixin.qq.com/kfid/kfc0c21cc4fdc3cbb80'
                        }
                      />
                    }
                  >
                    <Title level={5} style={{ margin: '0px 6px' }}>
                      企微：有速度科技
                    </Title>
                  </Popover>
                  {/* )} */}
                </div>
              </li>
              <li>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img src="/hi_phone.png" width={24} height={24} alt="电话" />
                  <Title level={5} style={{ margin: '0px 6px' }}>
                    电话：19183210205
                  </Title>
                </div>
              </li>
            </ul>
          </div>
        </Content>
        <Footer
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.88)', height: '120px' }}
        >
          <div
            style={{
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Text style={{ color: '#FFFFFF', fontSize: '14px' }}>
              {`Copyright © ${CURRENT_YEAR} 成都有速度科技有限公司`}
            </Text>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src="/security.png" width={16} height={17.29} alt="公安" />
              <Link
                href={`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${RECORD_CODE}`}
                target="_blank"
              >
                <Text
                  style={{
                    color: '#939393',
                    fontSize: '12px',
                    margin: '0px 4px',
                  }}
                >
                  {`川公网安备${RECORD_CODE}号`}
                </Text>
              </Link>
            </div>

            <Link
              href="https://beian.miit.gov.cn/#/Integrated/index"
              target="_blank"
            >
              <Text
                style={{
                  color: '#939393',
                  fontSize: '12px',
                  margin: '0px 6px',
                }}
              >
                蜀ICP备2024095604号
              </Text>
            </Link>
          </div>
        </Footer>
      </Layout>
    </div>
  );
};

export default Home;
