import { useEffect } from 'react';

const Page404 = () => {
  useEffect(() => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `//qzonestyle.gtimg.cn/qzone/hybrid/app/404/search_children.js`;
    script.setAttribute('homePageUrl', '/');
    script.setAttribute('homePageName', '返回首页');
    document.getElementsByTagName('head')[0].appendChild(script);
    return () => {
      // 相当于 componentWillUnmount
    };
  }, []);

  return <></>;
};

export default Page404;
