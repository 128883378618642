import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './home/home';
import RrivacyPolicy from './relationship/privacy-policy';
import Page404 from './404';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/relationship/privacy-policy" Component={RrivacyPolicy} />
        <Route path="*" Component={Page404} />
      </Routes>
    </Router>
  );
};

export default App;
